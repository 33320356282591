import {Totals, TotalsDataHook} from '@wix/wixstores-client-common-components';
import React from 'react';
import {Text, TextPriority} from 'wix-ui-tpa';
import {classes} from './TotalsSection.st.css';
import {ILocaleKeys, useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {shorten} from '../../../../domain/utils/shorten';
import {AppliedCouponModel} from '../../../../domain/models/AppliedCoupon.model';
import {SubscriptionFrequency} from '@wix/ambassador-ecom-v1-checkout/types';
import {SubscriptionModel} from '../../../../domain/models/Subscription.model';

export enum TotalsSectionDataHooks {
  Section = 'TotalsSectionDataHooks.Section',
  TotalsSectionWrapper = 'TotalsSectionDataHooks.TotalsSectionWrapper',
  ShippingRowValue = 'TotalsSectionDataHooks.ShippingRowValue',
  TotalSection = 'TotalsSectionDataHooks.TotalSection',
  TotalRow = 'TotalsSectionDataHooks.TotalRow',
  SubtotalRow = 'TotalsSectionDataHooks.SubtotalRow',
  ShippingRow = 'TotalsSectionDataHooks.ShippingRow',
  TaxRow = 'TotalsSectionDataHooks.TaxRow',
  AdditionalFeeRow = 'TotalsSectionDataHooks.AdditionalFeeRow',
  AdditionalFeesSection = 'TotalsSectionDataHooks.AdditionalFeesSection',
  AppliedCouponRow = 'TotalsSectionDataHooks.AppliedCouponRow',
  GiftCardRow = 'TotalsSectionDataHooks.GiftCardRow',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const TotalsSection = () => {
  const {checkout} = useControllerProps().checkoutStore;
  const {isSelectedShippingOptionPickup, canShipToDestination} = useControllerProps().deliveryMethodStore;
  const {checkoutSettings} = useControllerProps().checkoutSettingsStore;
  const localeKeys = useLocaleKeys();

  const numberOfAdditionalFees = checkout.additionalFees.length;

  const getSubtotalRowTitleText = (): string => {
    if (checkoutSettings.taxOnProduct) {
      return localeKeys.checkout.order_summary.totals.items();
    }
    return localeKeys.checkout.order_summary.totals.subtotal();
  };

  const isFreeShippingCoupon = (coupon: AppliedCouponModel): boolean => coupon.couponType === 'FreeShipping';

  const getCouponRowValueText = (coupon: AppliedCouponModel): string => {
    if (isFreeShippingCoupon(coupon)) {
      return localeKeys.checkout.order_summary.totals.discount.free_shipping();
    }
    return `-${coupon.price.formattedAmount}`;
  };

  const getShippingRowTitleText = (): string => {
    if (isSelectedShippingOptionPickup) {
      return localeKeys.checkout.orderSummary.totals.pickup.label();
    }
    return localeKeys.checkout.order_summary.totals.shipping();
  };

  const getShippingRowValueText = (): string => {
    if (!canShipToDestination) {
      return localeKeys.checkout.summary.shipping.noAmount();
    }
    if (
      checkout.priceSummary.shipping.amount === 0 ||
      (checkout.appliedCoupon && isFreeShippingCoupon(checkout.appliedCoupon))
    ) {
      return localeKeys.checkout.order_summary.totals.free();
    }
    return checkout.priceSummary.shipping.formattedAmount;
  };

  const renderAdditionalFees = () => {
    return numberOfAdditionalFees === 1 ? (
      <Totals.TotalsRow
        dataHook={TotalsSectionDataHooks.AdditionalFeeRow}
        title={checkout.additionalFees[0].name}
        value={checkout.additionalFees[0].priceBeforeTax.formattedAmount}
      />
    ) : (
      <Totals.TotalsSection
        title={localeKeys.checkout.additionalFees()}
        dataHook={TotalsSectionDataHooks.AdditionalFeesSection}>
        {checkout.additionalFees.map((fee) => (
          <Totals.TotalsRow
            dataHook={TotalsSectionDataHooks.AdditionalFeeRow}
            key={fee.code}
            title={fee.name}
            value={fee.priceBeforeTax.formattedAmount}
          />
        ))}
      </Totals.TotalsSection>
    );
  };

  return (
    <section
      className={classes.totalsContainer}
      data-hook={TotalsSectionDataHooks.Section}
      aria-label={localeKeys.checkout.orderTotal.fullSection.ariaLabel()}>
      <Totals dataHook={TotalsSectionDataHooks.TotalsSectionWrapper}>
        <Totals.TotalsRow
          dataHook={TotalsSectionDataHooks.SubtotalRow}
          title={getSubtotalRowTitleText()}
          value={checkout.priceSummary.subtotal.formattedAmount}
        />
        {!!numberOfAdditionalFees && renderAdditionalFees()}
        {!!checkout.appliedCoupon && !checkout.calculationErrors.couponError && (
          <Totals.TotalsRow
            dataHook={TotalsSectionDataHooks.AppliedCouponRow}
            title={localeKeys.checkout.order_summary.totals.discount.$value({
              couponCode: shorten(checkout.appliedCoupon.code.toUpperCase(), 10, '...'),
            })}
            value={getCouponRowValueText(checkout.appliedCoupon)}
          />
        )}
        {!!checkout.giftCard && checkout.giftCard.price.amount > 0 && (
          <Totals.TotalsRow
            dataHook={TotalsSectionDataHooks.GiftCardRow}
            title={localeKeys.checkout.order_summary.totals.giftcard()}
            value={`-${checkout.giftCard.price.formattedAmount}`}
          />
        )}
        {checkout.hasShippableItems && (
          <Totals.TotalsRow
            dataHook={TotalsSectionDataHooks.ShippingRow}
            title={getShippingRowTitleText()}
            value={() => (
              <Text
                data-hook={TotalsSectionDataHooks.ShippingRowValue}
                aria-label={!canShipToDestination ? localeKeys.checkout.orderTotal.noShipping.ariaLabel() : undefined}>
                {getShippingRowValueText()}
              </Text>
            )}
          />
        )}
        <Totals.TotalsRow
          dataHook={TotalsSectionDataHooks.TaxRow}
          title={checkoutSettings.taxName || localeKeys.checkout.order_summary.totals.tax()}
          value={checkout.priceSummary.tax.formattedAmount}
        />
        <Totals.TotalsSection dataHook={TotalsSectionDataHooks.TotalSection}>
          <Totals.TotalsRow
            dataHook={TotalsSectionDataHooks.TotalRow}
            title={() => (
              <Text className={classes.totalRowText} data-hook={TotalsDataHook.TotalsRowTitle}>
                {localeKeys.checkout.order_summary.totals.total()}
              </Text>
            )}
            value={() => (
              <>
                <Text className={classes.totalRowText}>{checkout.payNowTotalAfterGiftCard.formattedAmount}</Text>
                {checkout.lineItems?.[0]?.subscription && (
                  <Text className={classes.totalRowSubscriptionText} priority={TextPriority.secondary}>
                    {getTotalSubscriptionText(checkout.lineItems[0].subscription, localeKeys)}
                  </Text>
                )}
              </>
            )}
          />
        </Totals.TotalsSection>
      </Totals>
    </section>
  );
};

const getTotalSubscriptionText = (subscription: SubscriptionModel, localeKeys: ILocaleKeys): string => {
  if (subscription.interval > 2) {
    switch (subscription.frequency) {
      case SubscriptionFrequency.DAY: {
        return localeKeys.checkout.page_plan_frequency_description_days_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
      case SubscriptionFrequency.WEEK: {
        return localeKeys.checkout.page_plan_frequency_description_weeks_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
      case SubscriptionFrequency.MONTH: {
        return localeKeys.checkout.page_plan_frequency_description_months_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
      case SubscriptionFrequency.YEAR: {
        return localeKeys.checkout.page_plan_frequency_description_years_plural_label({
          numberOfFrequencyUnits: subscription.interval,
        });
      }
    }
  }
  switch (subscription.frequency) {
    case SubscriptionFrequency.DAY: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_days_label()
        : localeKeys.checkout.page_plan_frequency_description_day_singular_label();
    }
    case SubscriptionFrequency.WEEK: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_weeks_label()
        : localeKeys.checkout.page_plan_frequency_description_week_singular_label();
    }
    case SubscriptionFrequency.MONTH: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_months_label()
        : localeKeys.checkout.page_plan_frequency_description_month_singular_label();
    }
    case SubscriptionFrequency.YEAR: {
      return subscription.interval === 2
        ? localeKeys.checkout.page_plan_frequency_description_2_years_label()
        : localeKeys.checkout.page_plan_frequency_description_year_singular_label();
    }
  }
  return '';
};
