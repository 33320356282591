import React, {useEffect} from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {StepName} from '../../../../domain/utils/bi.util';
import {ErrorDialog} from '../ErrorDialog';

export enum PlaceOrderErrorDialogDataHooks {
  dialog = 'PlaceOrderErrorDialogDataHooks.Dialog',
}

export const PlaceOrderErrorDialog = () => {
  const {
    checkoutStore: {placeOrderError, onPlaceOrderErrorDialogShown, onPlaceOrderErrorDialogClosed},
  } = useControllerProps();

  const handleDialogOnClose = () => {
    onPlaceOrderErrorDialogClosed(StepName.PLACE_ORDER_FAST_FLOW);
  };

  useEffect(() => {
    onPlaceOrderErrorDialogShown(StepName.PLACE_ORDER_FAST_FLOW);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorDialog
      onClose={handleDialogOnClose}
      error={placeOrderError}
      dataHook={PlaceOrderErrorDialogDataHooks.dialog}
    />
  );
};
