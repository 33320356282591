import React, {useState} from 'react';
import {Button, Dialog, Text, TextTypography} from 'wix-ui-tpa';
import {CheckoutErrorModel} from '../../../domain/models/CheckoutError.model';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../Widget/ControllerContext';
import {hasPickupOption} from '../../../domain/utils/bi.util';
import {getErrorPropsFromCode} from '../../../domain/utils/errors';
import {classes} from './ErrorDialog.st.css';

export enum ErrorDialogDataHooks {
  Title = 'ErrorDialogDataHooks.Title',
  Description = 'ErrorDialogDataHooks.Description',
  Button = 'ErrorDialogDataHooks.Button',
}

interface ErrorDialogProps {
  dataHook: string;
  error?: CheckoutErrorModel;
  onOpen?: () => void;
  onClose: () => void;
}

export const ErrorDialog = ({error, onClose, dataHook}: ErrorDialogProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {checkout},
    navigationStore: {clickOnBackToSite, clickOnReturnToCart},
  } = useControllerProps();

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  const handleDialogOnClose = () => {
    onClose();
    setIsDialogOpen(false);
  };

  const handleClickOnReturnToCart = () => {
    void clickOnReturnToCart();
  };

  const errorToDisplay = getErrorPropsFromCode(
    error,
    localeKeys,
    {
      clickOnBackToSite,
      handleClickOnReturnToCart,
      handleDialogOnClose,
    },
    hasPickupOption(checkout),
    Boolean(checkout.cartId)
  );

  return (
    <Dialog data-hook={dataHook} isOpen={isDialogOpen} onClose={handleDialogOnClose}>
      <div className={classes.root}>
        <div className={classes.content}>
          <Text data-hook={ErrorDialogDataHooks.Title} typography={TextTypography.largeTitle}>
            {errorToDisplay.title}
          </Text>
          <Text data-hook={ErrorDialogDataHooks.Description} typography={TextTypography.runningText}>
            {errorToDisplay.description}
          </Text>
        </div>
        <Button
          data-hook={ErrorDialogDataHooks.Button}
          className={classes.button}
          onClick={() => errorToDisplay.action.fn()}
          upgrade>
          {errorToDisplay.action.name}
        </Button>
      </div>
    </Dialog>
  );
};
