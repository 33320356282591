import React from 'react';
import {classes} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {LineItemRow} from './LineItem/LineItem';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {SecureCheckout} from './SecureCheckout/SecureCheckout';
import {Text, TextButton} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {SlotId} from '../../../domain/utils/slotId';
import {CheckoutSlot} from '../CheckoutSlot';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {PlaceOrderButton} from '../PlaceOrderButton/PlaceOrderButton';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import {SPECS} from '../constants';

export enum SummaryDataHooks {
  LineItemSection = 'SummaryDataHooks.LineItemSection',
  titleText = 'SummaryDataHooks.title',
  editCartButton = 'SummaryDataHooks.editCartButton',
  couponSectionInput = 'SummaryDataHooks.couponSectionInput',
  giftCardSectionInput = 'SummaryDataHooks.giftCardSectionInput',
  numberOfOrders = 'SummaryDataHooks.numberOfOrders',
}

export const Summary = ({dataHook}: {dataHook: string}) => {
  const localeKeys = useLocaleKeys();
  const {checkout, shouldShowGiftCardSection, shouldShowCouponSection} = useControllerProps().checkoutStore;
  const {editCartUrl, clickOnEditCart} = useControllerProps().navigationStore;
  const {isMobile} = useEnvironment();
  const {experiments} = useExperiments();

  const addSlotToCheckout = experiments.enabled(SPECS.AddSlotToCheckout);
  const addAfterSummarySlotToCheckout =
    addSlotToCheckout || experiments.enabled(SPECS.AddUnderTheSummarySlotToCheckout);

  return (
    <div className={classes.sticky} data-hook={dataHook}>
      {addSlotToCheckout && (
        /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.BeforeSummaryCheckoutSlot} />
      )}
      <div className={classes.summary}>
        <div className={classes.title}>
          <div className={classes.header}>
            <Text tagName={'h2'} className={classes.titleText} data-hook={SummaryDataHooks.titleText}>
              {localeKeys.checkout.order_summary.$value()}
            </Text>{' '}
            <Text
              data-hook={SummaryDataHooks.numberOfOrders}
              className={classes.numberOfOrders}
              aria-label={localeKeys.checkout.orderSummary.itemAmount_ariaLabel({itemNumber: checkout.numberOfItems})}>
              ({checkout.numberOfItems})
            </Text>
          </div>
          {checkout.cartId && (
            <TextButton
              as={'a'}
              href={editCartUrl}
              onClick={() => clickOnEditCart()}
              className={classes.editCartButton}
              data-hook={SummaryDataHooks.editCartButton}>
              {localeKeys.checkout.edit_cart()}
            </TextButton>
          )}
        </div>
        <section
          data-hook={SummaryDataHooks.LineItemSection}
          aria-label={localeKeys.checkout.orderSummary.fullSection.ariaLabel()}>
          <ul className={classNames(classes.itemsList, isMobile && classes.itemsListMobile)}>
            {checkout.lineItems &&
              checkout.lineItems.map((lineItem) => (
                <LineItemRow
                  lineItem={lineItem}
                  key={lineItem.id}
                  useLayoutForLongPrice={checkout.hasItemsWithLongPrice}
                />
              ))}
          </ul>
        </section>
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterLineItemsSummaryCheckoutSlot} />
        )}
        <div className={classes.couponAndGiftCard}>
          {shouldShowCouponSection && <CouponInput />}
          {shouldShowGiftCardSection && <GiftCardInput />}
        </div>
        <TotalsSection />
        <PlaceOrderButton isMobile={true} />
      </div>
      <SecureCheckout />
      {addAfterSummarySlotToCheckout && (
        /* istanbul ignore next: test slot */ <CheckoutSlot slotId={SlotId.AfterSummaryCheckoutSlot} />
      )}
    </div>
  );
};
