import {classes} from './ItemLayout.st.css';
import React, {ReactNode} from 'react';
import classNames from 'classnames';

export interface LayoutProps {
  headerWithColumns: boolean;
  Aside: ReactNode;
  Header1: ReactNode;
  Header2: ReactNode;
  Content: ReactNode;
  Footer?: ReactNode;
}

export const ItemLayout = ({headerWithColumns, Aside, Header1, Header2, Content, Footer}: LayoutProps) => {
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.left}>{Aside}</div>
        <div className={classes.right}>
          <div
            className={classNames(
              classes.header,
              headerWithColumns ? classes.headerWithColumns : classes.headerWithRows
            )}>
            <div className={classes.header1}>{Header1}</div>
            <div
              className={headerWithColumns ? classNames(classes.header2, classes.header2AlignRight) : classes.header2}>
              {Header2}
            </div>
          </div>
          <div className={classes.content}>{Content}</div>
        </div>
      </div>
      {Footer && <div className={classes.footer}>{Footer}</div>}
    </div>
  );
};
