import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {InputWithApply} from '../InputWithApply/InputWithApply';
import {classes} from './CustomField.st.css';
import {MAX_CUSTOM_FIELD_VALUE_LENGTH} from '../../constants';

export enum CustomFieldDataHook {
  root = 'CustomField.root',
}

export const CustomField = () => {
  const {checkoutSettingsStore, checkoutStore} = useControllerProps();
  const {checkoutSettings} = checkoutSettingsStore;
  const {setCustomField, shouldShowCustomField} = checkoutStore;
  const localeKeys = useLocaleKeys();
  const customFieldTitle = checkoutSettings.customField.title;

  const handleApplyButtonClicked = (inputValue: string) => {
    if (validateCustomField(inputValue)) {
      void setCustomField(inputValue);
    }
  };

  const validateCustomField = (inputValue: string) => {
    return !(checkoutSettings.customField?.mandatory && shouldShowCustomField && !inputValue);
  };

  return (
    <InputWithApply
      className={classes.root}
      dataHook={CustomFieldDataHook.root}
      title={checkoutSettings.customField?.mandatory ? `*${customFieldTitle}` : customFieldTitle}
      onApply={handleApplyButtonClicked}
      buttonText={localeKeys.checkout.additionalInfo.custom_field.apply()}
      validateInput={validateCustomField}
      errorMessage={localeKeys.checkout.additionalInfo.missing_error.required()}
      maxLength={MAX_CUSTOM_FIELD_VALUE_LENGTH}
      showCharCount={true}
    />
  );
};
