import React, {useEffect} from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {ErrorDialog} from '../ErrorDialog';

export enum CheckoutErrorDialogDataHooks {
  dialog = 'CheckoutErrorDialogDataHooks.Dialog',
}

export const CheckoutErrorDialog = () => {
  const {
    checkoutStore: {onCheckoutErrorDialogShown, checkout},
    navigationStore: {clickOnBackToSite},
  } = useControllerProps();

  const handleDialogOnClose = () => {
    clickOnBackToSite();
  };

  useEffect(() => {
    onCheckoutErrorDialogShown();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorDialog
      onClose={handleDialogOnClose}
      error={checkout.calculationErrors.noItemsError}
      dataHook={CheckoutErrorDialogDataHooks.dialog}
    />
  );
};
