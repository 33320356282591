import {useControllerProps} from './Widget/ControllerContext';
import React, {useEffect} from 'react';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {SlotId} from '../../domain/utils/slotId';

export const CheckoutSlot = /* istanbul ignore next: test slot */ ({slotId}: {slotId: SlotId}) => {
  const {slotsStore} = useControllerProps();

  useEffect(
    () => {
      slotsStore.setSlotsParams(slotId);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  return <SlotsPlaceholder slotId={slotId} defaultHeight={100} />;
};
